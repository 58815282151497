import * as React from 'react';

export default class YoutubeLiveStream extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            youtubeVideoId: '',
            iframeLoaded: false
        }
    }

    componentDidMount() {
        fetch('https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCKioNqOX_kOCLcSIWPL_lxQ&type=video&eventType=live&key=AIzaSyAbTQA4IMlwiUaM2K-QwoIhT6oe1XP8YiA')
            .then((data) => {
                return data.json();
            })
            .then(json => {
                if (json && json.items && json.items[0] && json.items[0].id) {
                    this.setState({youtubeVideoId: json.items[0].id.videoId})
                }
            })
    }

    iframeLoaded = () => {
        this.setState({iframeLoaded: true})
    }

    render() {
        let loadingHolderClass = 'loading-holder';

        if (this.state.iframeLoaded && this.state.youtubeVideoId !== '') {
            loadingHolderClass += ' loaded';
        }

        return (
            <div className={loadingHolderClass}>
                <div className="loader-content">
                    <iframe className="radio-player" title="radio-player" width="100%" height="515" src={'https://www.youtube-nocookie.com/embed/' + this.state.youtubeVideoId} frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen onLoad={this.iframeLoaded}/>
                </div>
                <div className="loader-placeholder">
                    <div className="skeleton radio-player" />
                </div>
            </div>
        )
    }
}
