import * as React from 'react';
import logoWhite from '../../assets/img/logo_white.png';
import logoBlack from '../../assets/img/logo_black.png';
import {withRouter} from 'react-router-dom';
import {Switch} from 'antd';
import {
    YoutubeFilled,
    InstagramFilled,
    FacebookFilled,
    TwitterOutlined
} from '@ant-design/icons';
import {ThemeContext} from './ThemeContextProvider';
const AviencloudConfig = window.AviencloudConfig;

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {smallNav: false};
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 40) {
            if (!this.state.smallNav) {
                this.setState({smallNav: true})
            }
        } else if (this.state.smallNav) {
            this.setState({smallNav: false})
        }
    }

    render() {
        let logos = {
          light: logoBlack,
          dark: logoWhite,
        };

        let hamburgerClass = 'hamburger hamburger--spin';
        let headerClass = 'header';
        if (this.props.navOpen) {
            hamburgerClass += ' is-active';
        }

        if (this.state.smallNav) {
            headerClass += ' small';
        }

        let homeClass = 'item', submissionClass = 'item', contactClass = 'item', newsletterClass = 'item';
        if (this.props.location.pathname === '/') {
            homeClass += ' active';
            window.document.title = 'Aviencloud - Home';
        } else if (this.props.location.pathname === '/submission') {
            submissionClass += ' active';
            window.document.title = 'Aviencloud - Submit your Music or Artwork';
        } else if (this.props.location.pathname === '/contact') {
            contactClass += ' active';
            window.document.title = 'Aviencloud - Contact';
        } else if (this.props.location.pathname === '/newsletter') {
            newsletterClass += ' active';
            window.document.title = 'Aviencloud - Newsletter';
        }

        return (
            <ThemeContext.Consumer>
                {context => (
                    <nav>
                        <div className={headerClass}>
                            <div className="logo" onClick={() => this.props.history.push('/')}>
                                <img src={logos[context.theme]} alt=""/>
                            </div>
                            <div className="menu" onClick={this.props.toggleNavigation}>
                                <div className={hamburgerClass}>
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nav-items">
                            <div className={homeClass} onClick={() => {
                                this.props.history.push('/');
                                this.props.toggleNavigation();
                            }}>
                                Home
                            </div>
                            <div className={submissionClass}  onClick={() => {
                                this.props.history.push('/submission');
                                this.props.toggleNavigation();
                            }}>
                                Submission
                            </div>
                            <div className={newsletterClass}  onClick={() => {
                                this.props.history.push('/newsletter');
                                this.props.toggleNavigation();
                            }}>
                                Newsletter
                            </div>
                            <div className={contactClass}  onClick={() => {
                                this.props.history.push('/imprint');
                                this.props.toggleNavigation();
                            }}>
                                Imprint
                            </div>
                        </div>
                        <div className="nav-subarea">
                            <div className="social-links">
                                <div className="social-link" onClick={() => window.open(AviencloudConfig.generalInfo.socialInfo.youtube, '_blank')}>
                                    <YoutubeFilled />
                                </div>
                                <div className="social-link" onClick={() => window.open(AviencloudConfig.generalInfo.socialInfo.instagram, '_blank')}>
                                    <InstagramFilled />
                                </div>
                                <div className="social-link" onClick={() => window.open(AviencloudConfig.generalInfo.socialInfo.facebook, '_blank')}>
                                    <FacebookFilled />
                                </div>
                                <div className="social-link" onClick={() => window.open(AviencloudConfig.generalInfo.socialInfo.twitter, '_blank')}>
                                    <TwitterOutlined />
                                </div>
                            </div>
                            <div className="theme-settings">
                                <span>Dark</span>
                                <Switch checked={context.theme === 'light'} onChange={checked => {
                                    context.setTheme(checked ? 'light' : 'dark')
                                }} />
                                <span>Light</span>
                            </div>
                        </div>
                    </nav>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default withRouter(Navigation);
