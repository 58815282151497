import * as React from 'react';
import loadingLogo from '../../assets/img/logo-circle-loading.png';

export default class Submission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeLoaded: false
        }
    }

    iframeLoaded = () => {
        this.setState({iframeLoaded: true})
    }

    render() {
        let loadingHolderClass = 'loading-holder forms';

        if (this.state.iframeLoaded) {
            loadingHolderClass += ' loaded';
        }

        return (
            <div style={{height: '100vh'}}>
                <div className={loadingHolderClass}>
                    <div className="loader-content">
                        <iframe title="Submission Form" src="https://docs.google.com/forms/d/e/1FAIpQLSdycyluDp33dLQIOOqeGIG3EkAn4K9uwlf1iOcrQshflUNvAA/viewform" frameBorder={0} style={{width: '100%', height: '100%'}} onLoad={this.iframeLoaded}/>
                    </div>
                    <div className="loader-placeholder">
                        <img src={loadingLogo} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}
