import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './view/home/Home';
import Submission from './view/submission/Submission';
import Navigation from './view/components/Navigation';
import {BackTop} from 'antd';
import Newsletter from './view/newsletter/Newsletter';
import {ThemeContextProvider, ThemeContext} from './view/components/ThemeContextProvider';
import Contact from './view/contact/Contact';
import NotFound from './view/not-found/NotFound';
import Social from './view/social/Social';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {navOpen: false}
    }

    toggleNavigation = () => {
        this.setState({navOpen: !this.state.navOpen});

        if (!this.state.navOpen) {
            document.body.style.overflow = 'hidden';
            window.scrollTo({ top: 0});
        } else {
            setTimeout(() => {
                document.body.style.overflow = '';
            }, 500)
        }
    }

    onPageClick = () => {
        if (this.state.navOpen) {
            this.toggleNavigation();
        }
    }


    render() {
        let navOpen = 'App ';
        if (this.state.navOpen) {
            navOpen += ' show';
        }

        return (
            <ThemeContextProvider>
                <Router>
                    <ThemeContext.Consumer>
                        {context => (
                            <Fragment>
                                <div className={navOpen + ' ' + context.theme} onScroll={this.onPageScroll}>
                                    <Navigation navOpen={this.state.navOpen} toggleNavigation={this.toggleNavigation} />
                                    <div className="pages" onClick={this.onPageClick}>
                                        <div className="content">
                                            <Switch>
                                                <Route path="/" exact component={Home}/>
                                                <Route path="/submission" component={Submission}/>
                                                <Route path="/newsletter" component={Newsletter}/>
                                                <Route path="/imprint" component={Contact}/>
                                                <Route path="/social" component={Social}/>
                                                <Route component={NotFound}/>
                                            </Switch>
                                            <footer>
                                                <div className="copyright">
                                                    Aviencloud &copy; {new Date().getFullYear()}
                                                </div>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                                <BackTop />
                            </Fragment>
                        )}
                    </ThemeContext.Consumer>
                </Router>
            </ThemeContextProvider>
        );
    }

}

export default App;
