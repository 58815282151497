import React, { Component } from 'react';

/**
 * Component that alerts if you click outside of it
 */
export default class ClickOutside extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.props.onOutsideClick !== undefined) {
            let contained = false;

            if (this.props.toggle !== undefined) {
                if (Array.isArray(this.props.toggle)) {
                    this.props.toggle.forEach((toggle) => {
                        if (toggle.contains(event.target)) {
                            contained = true;
                        }
                    });
                } else if (this.props.toggle.contains(event.target)) {
                    contained = true;
                }
            }

            if (!contained) {
                this.props.onOutsideClick(event);
            }

        }
    }

    render() {
        return <div className={this.props.className} ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}
