import * as React from 'react';
import {ThemeContext} from './ThemeContextProvider';

export default class YoutubeLiveStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iframeLoaded: false
        }
    }

    iframeLoaded = () => {
        this.setState({iframeLoaded: true})
    }

    render() {
        let loadingHolderClass = 'loading-holder';

        if (this.state.iframeLoaded) {
            loadingHolderClass += ' loaded';
        }

        return (
            <ThemeContext.Consumer>
                {context => (
                    <div className={loadingHolderClass}>
                        <div className="loader-content">
                            <iframe width="100%" height="600px" className="discord-widget" title="discord-widget"
                                    src={'https://discordapp.com/widget?id=324577424341860353&theme=' + context.theme}
                                    allow-transparency="true" frameBorder="0" onLoad={this.iframeLoaded}/>
                        </div>
                        <div className="loader-placeholder">
                            <div className="skeleton radio-player"/>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        )
    }
}
