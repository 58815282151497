import * as React from 'react';
import {Button, Col, Row, Typography, Carousel} from 'antd';
import Playlist from '../components/Playlist';
import PlatformButton from '../components/PlatformButton';
import {withRouter} from 'react-router-dom';
import {ThemeContext} from '../components/ThemeContextProvider';
import YoutubeLiveStream from '../components/YoutubeLiveStream';
import DiscordEmbed from '../components/DiscordEmbed';
const { Title, Text  } = Typography;
const AviencloudConfig = window.AviencloudConfig;

class Home extends React.Component{
    render() {
        return (
            <ThemeContext.Consumer>
                {context => (
                    <div className="home-view">
                        <Carousel autoplay autoplaySpeed={8000} draggable={true} pauseOnHover={true}>
                            <div className="slider slide--1">
                                <div className="hero-content">
                                    <div className="text">
                                        <div className="text">
                                            <Title style={{color: '#fff'}}>Welcome to Aviencloud</Title>
                                            <Text style={{color: 'rgba(255, 255, 255, 0.7)'}}>Aviencloud is a music and artwork promoting channel, dedicated to promoting nu disco, funk, electronic, soul, r&b, ambient, chill, lofi hip hop, jazz, hip hop, jazz hip hop as well as Relax & Mellow vibes.</Text>
                                        </div>
                                        <Button type="primary" onClick={() => {window.open(AviencloudConfig.generalInfo.cta)}}>Listen to some Tunes</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="slider slide--2">
                                <div className="hero-content">
                                    <div className="text">
                                        <Title style={{color: '#fff'}}>Are you a Producer ?</Title>
                                        <Text style={{color: 'rgba(255, 255, 255, 0.7)'}}>Interested in promoting your Music through Aviencloud ?</Text>
                                    </div>
                                    <Button type="primary" onClick={() => this.props.history.push('/submission')}>Submit your Music</Button>
                                </div>
                            </div>
                            <div className="slider slide--3">
                                <div className="hero-content">
                                    <div className="text">
                                        <Title style={{color: '#fff'}}>Are you an Artist ?</Title>
                                        <Text style={{color: 'rgba(255, 255, 255, 0.7)'}}>Do you want to see your Artwork on the Aviencloud Channel ?</Text>
                                    </div>
                                    <Button type="primary" onClick={() => this.props.history.push('/submission')}>Submit your Artwork</Button>
                                </div>
                            </div>
                        </Carousel>
                        <div className="box">
                            <div className="box-heading">
                                Listen On
                            </div>
                            <div className="box-content">
                                <div className="social-link-box">
                                    <Row>
                                        <Col xl={6} lg={12} sm={12} xs={24}>
                                            <PlatformButton type="youtube" to={AviencloudConfig.generalInfo.socialInfo.youtube} inverted="mobile">YouTube</PlatformButton>
                                        </Col>
                                        <Col xl={6} lg={12} sm={12} xs={24}>
                                            <PlatformButton type="spotify" to={AviencloudConfig.generalInfo.socialInfo.spotify}>Spotify</PlatformButton>
                                        </Col>
                                        <Col xl={6} lg={12} sm={12} xs={24}>
                                            <PlatformButton type="soundcloud" to={AviencloudConfig.generalInfo.socialInfo.soundcloud} inverted="tablet, mobile">SoundCloud</PlatformButton>
                                        </Col>
                                        <Col xl={6} lg={12} sm={12} xs={24}>
                                            <PlatformButton type="apple-music" to={AviencloudConfig.generalInfo.socialInfo.appleMusic} inverted="tablet">Apple Music</PlatformButton>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-heading">
                                Playlists
                            </div>
                            <div className="box-content">
                                <Row gutter={30} justify="center">
                                    {
                                        AviencloudConfig.playlists.map((playlist, index) => {
                                            return (
                                                <Col xl={4} lg={12} md={12} sm={24} xs={24} key={'playlist-' + index} style={{display: 'flex', justifyContent: 'center'}}>
                                                    <Playlist playlistId={playlist.playlistId} title={playlist.title} img={playlist.image} />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                        <div className="box" id="radio">
                            <div className="box-heading">
                                Radio
                            </div>
                            <div className="box-content">
                                <Row type="flex" justify="center">
                                    <Col span={24} xl={12}>
                                        <YoutubeLiveStream/>
                                    </Col>
                                </Row>


                            </div>
                        </div>

                        <div className="box">
                            <div className="box-heading">
                                Discord
                            </div>
                            <div className="box-content">
                                <Row type="flex" justify="center">
                                    <Col span={24} xl={12}>
                                        <DiscordEmbed />
                                    </Col>
                                </Row>


                            </div>
                        </div>

                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default withRouter(Home);
