import * as React from 'react';
import ClickOutside from './ClickOutside';

export default class Playlist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {isOpen: false};
    }


    render() {
        let playlistClass = 'playlist';
        if (this.state.isOpen) {
            playlistClass += ' open';
        }

        return (
            <ClickOutside className={playlistClass} onOutsideClick={() => this.setState({isOpen: false})}>
                <div className="flipper">
                    <div className="preview"
                         style={{background: 'url(' + this.props.img + ') center', backgroundSize: 'contain'}}
                         onClick={() => this.setState({isOpen: !this.state.isOpen})}/>
                    <div className="spotify-embed">
                        <iframe title="Playlist Frame"
                                src={'https://open.spotify.com/embed/playlist/' + this.props.playlistId}
                                width="100%" height="100%" frameBorder="0" allowtransparency="true"
                                allow="encrypted-media"/>
                    </div>
                </div>

                <p className="playlist-title">{this.props.title}</p>
            </ClickOutside>
        );
    }
}
