import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faApple, faSoundcloud, faFacebook, faInstagram, faGooglePlay, faYoutube, faSpotify, faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons'

export default class PlatformButton extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            subscriberCount: 0,
            logo: null
        }
    }

    openLink = () => {
        window.open(this.props.to,'_blank');
    }

    componentDidMount() {
        switch (this.props.type) {
            case 'youtube':
                this.loadYoutubeSubscriber();
                break;
            case 'play-music':
                this.setState({
                    logo: <FontAwesomeIcon icon={faGooglePlay} />,
                });
                break;
            case 'apple-music':
                this.setState({
                    logo: <FontAwesomeIcon icon={faApple} />,
                    subscriberCount: 1000
                });
                break;
            case 'instagram':
                this.setState({
                    logo: <FontAwesomeIcon icon={faInstagram} />,
                    subscriberCount: 3188
                });
                break;
            case 'facebook':
                this.setState({
                    logo: <FontAwesomeIcon icon={faFacebook} />,
                    subscriberCount: 3000
                });
                break;
            case 'soundcloud':
                this.setState({
                    logo: <FontAwesomeIcon icon={faSoundcloud} />,
                    subscriberCount: 20000
                });
                break;
            case 'twitter':
                this.setState({
                    logo: <FontAwesomeIcon icon={faTwitter} />,
                    subscriberCount: 20000
                });
                break;
            case 'discord':
                this.loadDiscordData();
                break;
            case 'spotify':
            default:
                this.setState({
                    logo: <FontAwesomeIcon icon={faSpotify} />,
                    subscriberCount: 4400
                });
        }
    }

    loadYoutubeSubscriber() {
        fetch('https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCKioNqOX_kOCLcSIWPL_lxQ&key=AIzaSyAbTQA4IMlwiUaM2K-QwoIhT6oe1XP8YiA')
            .then((data) => {
                return data.json();
            })
            .then(json => {
                if (json && json.items && json.items[0] && json.items[0].statistics) {
                    this.setState({
                        subscriberCount: json.items[0].statistics.subscriberCount,
                        logo: <FontAwesomeIcon icon={faYoutube} />
                    })
                }
            })
    }

    loadDiscordData() {
        fetch('https://discordapp.com/api/guilds/324577424341860353/widget.json')
            .then((data) => {
                return data.json();
            })
            .then(json => {
                if (json) {
                    this.setState({
                        subscriberCount: json.presence_count,
                        logo: <FontAwesomeIcon icon={faDiscord} />
                    });
                    console.log(json);
                }
            })
    }

    render() {
        let inverted = '';
        if (this.props.inverted) {
            if (this.props.inverted.includes('mobile')) {
                inverted += ' inverted-mobile';
            }
            if (this.props.inverted.includes('tablet')) {
                inverted += ' inverted-tablet';
            }
            if (this.props.inverted.includes('desktop')) {
                inverted += ' inverted-desktop';
            }
        }


        return (
            <div className={"platform-button type--" + this.props.type + inverted} onClick={this.openLink}>
                <div className="icon">
                    {this.state.logo}
                </div>
                <div className="text">
                    <div className="name">
                        {this.props.children}
                    </div>
                    <div className="follower-count">
                        {new Intl.NumberFormat().format(this.state.subscriberCount)} {this.props.type !== 'discord' ? 'follower' : 'online'}
                    </div>
                </div>
            </div>
        );
    }
}
