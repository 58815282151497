import * as React from 'react';
import {Col, Row} from 'antd';
import PlatformButton from '../components/PlatformButton';
const AviencloudConfig = window.AviencloudConfig;

export default class Social extends React.Component {
    render() {
        return (
            <div className="social">
                <div className="box">
                    <div className="box-heading">
                        Social links
                    </div>
                    <div className="box-content">
                        <div className="social-link-box">
                            <Row>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="youtube" to={AviencloudConfig.generalInfo.socialInfo.youtube} inverted="mobile">YouTube</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="spotify" to={AviencloudConfig.generalInfo.socialInfo.spotify}>Spotify</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="soundcloud" to={AviencloudConfig.generalInfo.socialInfo.soundcloud} inverted="tablet, mobile">SoundCloud</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="apple-music" to={AviencloudConfig.generalInfo.socialInfo.appleMusic} inverted="tablet">Apple Music</PlatformButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="facebook" to={AviencloudConfig.generalInfo.socialInfo.facebook} inverted="mobile, desktop">Facebook</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="instagram" to={AviencloudConfig.generalInfo.socialInfo.instagram} inverted="desktop">Instagram</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="twitter" to={AviencloudConfig.generalInfo.socialInfo.twitter} inverted="desktop, tablet, mobile">Twitter</PlatformButton>
                                </Col>
                                <Col xl={6} lg={12} sm={12} xs={24}>
                                    <PlatformButton type="discord" to={AviencloudConfig.generalInfo.socialInfo.discord} inverted="desktop, tablet">Discord</PlatformButton>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
