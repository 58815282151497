import * as React from 'react';
import {Button} from 'antd';

export default class NotFound extends React.Component {
    render() {
        return (
            <div className="contact">
                <div className="contact-header"/>
                <div className="box">
                    <div className="box-heading">
                        Content not found
                    </div>
                    <div className="box-content">
                        <p>The page you requested couldn't be found!</p>
                        <Button type="primary" onClick={() => this.props.history.push('/')}>Home</Button>
                    </div>
                </div>
            </div>
        );
    }
}
