import React, { useState } from 'react'

export const ThemeContext = React.createContext({
    theme: 'light',
    setTheme: () => {}
})

export const ThemeContextProvider = props => {
    const mql = window.matchMedia('screen and (prefers-color-scheme: light), screen and (prefers-color-scheme: no-preference)');
    const systemTheme = mql.matches ? 'light' : 'dark';
    const storedTheme = localStorage.getItem('aviencloud-theme') || systemTheme;

    const setTheme = (type, isSystem) => {
        if (!isSystem) {
            localStorage.setItem('aviencloud-theme', type);
        }
        setState({...state, theme: type})
    };

    const initState = {
        theme: storedTheme || 'light',
        setTheme: setTheme
    };


    mql.addEventListener('change', (value) => {
        const theme = value.matches ? 'light' : 'dark';
        setTheme(theme, true)
    });

    const [state, setState] = useState(initState);

    return (
        <ThemeContext.Provider value={state}>
            {props.children}
        </ThemeContext.Provider>
    );
};
